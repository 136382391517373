import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./MainPage.module.css";
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';
import TypedText from "./Typed";
import { useMediaQuery } from 'react-responsive';

const MainPage: FunctionComponent = () => {
  const [appsOpened, setAppsOpened] = useState(false);
  const [appsFinished, setAppsFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setLoading(true);
    let header = document.querySelector('#intro') as HTMLPreElement;
let anim = [
    { t: ">  ", ms: 200 },
    { t: ">_ ", ms: 200 },
    { t: ">  ", ms: 200 },
    { t: ">_ ", ms: 200 },
    { t: ">  ", ms: 200 },
    { t: ">_ ", ms: 200 },
    { t: ">  ", ms: 200 },
    { t: ">_ ", ms: 200 },
    { t: ">  ", ms: 200 },
    { t: ">_ ", ms: 200 },
    { t: ">./w_ ", ms: 100 },
    { t: ">./we_ ", ms: 100 },
    { t: ">./wel_ ", ms: 100 },
    { t: ">./welc_ ", ms: 100 },
    { t: ">./welco_ ", ms: 100 },
    { t: ">./welcom_ ", ms: 100 },
    { t: ">./welcome_ ", ms: 100 },
    { t: ">./welcome__ ", ms: 100 },
    { t: ">./welcome_t_ ", ms: 100 },
    { t: ">./welcome_to_ ", ms: 100 },
    { t: ">./welcome_to _ ", ms: 100 },
    { t: ">./welcome_to_s_ ", ms: 100 },
    { t: ">./welcome_to_sc_ ", ms: 100 },
    { t: ">./welcome_to_sco_ ", ms: 100 },
    { t: ">./welcome_to_scop_ ", ms: 100 },
    { t: ">./welcome_to_scope_ ", ms: 100 },
    { t: ">./welcome_to_scope._ ", ms: 100 },
    { t: ">./welcome_to_scope.s_ ", ms: 100 },
    { t: ">./welcome_to_scope.sh_", ms: 100 },
    { t: ">./welcome_to_scope.sh_", ms: 100 },
    { t: ">./welcome_to_scope.sh", ms: 200 },
    { t: ">./welcome_to_scope.sh", ms: 200 },
    { t: ">./welcome_to_scope.sh", ms: 200 },
    { t: ">./welcome_to_scope.sh", ms: 200 },
    { t: ">./welcome_to_scope.sh", ms: 200 },
];
let stepDenominator = 1;
window.scrollTo(0, 0);
if (window.localStorage.stepDenominator)
    stepDenominator = window.localStorage.stepDenominator;
let i = 0;
let update = () => {
    i++;
    if (i < anim.length){
        let step = anim[i];
        header.innerText = step.t;
        setTimeout(update, step.ms / stepDenominator);
    }
    else {
        header.style.transform='translateX(-50%)';
        header.style.opacity='0';
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }
}
if (window.location.hash) {
} else {
    update();
}
  }, []);
  
  useEffect(() => {
    const appsOpenedDate = new Date("1/13/2025 09:00:00");
    const appsFinishedDate = new Date("1/31/2025 23:59:59");
    const interval = setInterval(() => {
      const now = new Date();
      var difference = 0;
      const appsOpenedDifference = appsOpenedDate.getTime() - now.getTime();
      if (appsOpenedDifference >= 0){
        setAppsOpened(false);
        setAppsFinished(false);
        difference = appsOpenedDifference;
      } else {
        setAppsOpened(true);
        const appsFinishedDifference = appsFinishedDate.getTime() - now.getTime();
        if (appsFinishedDifference >= 0){
          setAppsFinished(false);
          difference = appsFinishedDifference;
        } else {
          setAppsFinished(true);
        }
      }
      if ((!appsFinished)){
        const d = Math.floor(difference / (1000 * 60 * 60 * 24));
        setDays(d);

        const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        setHours(h);

        const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        setMinutes(m);

        const s = Math.floor((difference % (1000 * 60)) / (1000));
        setSeconds(s);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const onGithubButtonImageClick = useCallback(() => {
    window.open("https://github.com/scopeusc");
  }, []);

  const onFacebookButtonImageClick = useCallback(() => {
    window.open("https://www.facebook.com/scopeusc/");
  }, []);

  const onInstagramButtonImageClick = useCallback(() => {
    window.open("https://www.instagram.com/scopeusc/");
  }, []);
var prevScrollpos = window.scrollY;
window.onscroll = function() {
  var currentScrollPos = window.scrollY;
  if (loading) {}
  else if (prevScrollpos > currentScrollPos || currentScrollPos < 1000) {
    document.getElementById("menuBar")!.style.visibility = "hidden";
  } else {
    document.getElementById("menuBar")!.style.visibility = "visible";
  }
  prevScrollpos = currentScrollPos;
}
  const onABOUTTextClick = useCallback(() => {
    window.location.href = "/#about";
  }, []);
  const onLogoClick = useCallback(() => {
    window.location.href = "/#top";
  }, []);
  const onSCOPECUPTextClick = useCallback(() => {
    // Please sync "Scope Cup" to the project
    window.location.href = "/cup";
  }, []);

  const onSPONSORTextClick = useCallback(() => {
    // Please sync "Sponsor Page" to the project
    window.location.href = "/sponsor";
  }, []);

  const onFAQTextClick = useCallback(() => {
    window.location.href = "/#faq";

  }, []);

  const onCOHORTTextClick = useCallback(() => {
    window.location.href = "/#cohort";
  }, []);

  const onLEARNTextClick = useCallback(() => {
    window.location.href = "/#learn";
  }, []);

  const onABOUTText1Click = useCallback(() => {
    window.location.href = "/#about";
  }, []);

  const onApplyNowClick = useCallback(() => {
    window.location.href = "/apply";
  }, []);

  const onFAQText1Click = useCallback(() => {
    window.location.href = "/#faq";
  }, []);

  const onCOHORTText1Click = useCallback(() => {
    window.location.href = "/#cohort";
  }, []);
  function goToLinkedin(member: any) {
      window.location.href = "http://www.linkedin.com/in/" + member + '/';
  };
  const onLEARNText1Click = useCallback(() => {
    window.location.href = "/#learn";
  }, []);

  const onPurpleinf1ImageClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='topText']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onABOUTText2Click = useCallback(() => {
    // const anchor = document.querySelector("[data-scroll-to='aboutText']");
    // if (anchor) {
    //   anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    // }
    window.location.href = "/#about";
  }, []);

  const onLEARNText2Click = useCallback(() => {
    window.location.href = "/#learn";
  }, []);

  const onCOHORTText2Click = useCallback(() => {
    window.location.href = "/#cohort";
  }, []);

  const onFAQText2Click = useCallback(() => {
    window.location.href = "/#faq";
  }, []);

  const onSPONSORText2Click = useCallback(() => {
    // Please sync "Sponsor Page" to the project
    window.location.href = "/sponsor";
  }, []);

  const onSCOPECUPText2Click = useCallback(() => {
    window.location.href = "/cup";
  }, []);

  return (
    <div className={styles.mainPage}>
      <head>
      <title>Scope | USC</title>
      <meta name="description" content="Scope Website" />
      <link rel="icon" type="image/x-icon" href="/public/silverInfinity.ico" />
    </head>
      {/* <Script type="text/javascript" src="/js/intro.js"></Script> */}
        <pre id="intro" className={styles.middle}></pre>
        { loading ? <div className="test"></div> :
    <div id="pageCntnt" className={styles.content}>
      { appsOpened && !appsFinished ?  <a href="/apply" target="_blank">
        <div className={styles.applyNow} onClick={onApplyNowClick}>Apply Now</div>
      </a> : <div className=""></div>}
      {/* <a href="/apply" target="_blank">
        <div className={styles.applyNow} onClick={onApplyNowClick}>Apply Now</div>
      </a> */}
      <img
        className={styles.testphoto31Icon}
        alt=""
        src="/testphoto3-1@2x.png"
      />
      <img
        className={styles.scopegradientorb2Icon}
        alt=""
        src="/scopegradientorb-2@2x.png"
      />
      <img
        className={styles.testphoto21Icon}
        alt=""
        src="/testphoto2-1@2x.png"
      />
      <img
        className={styles.globewShadow1Icon}
        alt=""
        src="/globew-shadow-11@2x.png"
        id="faq"
      />
      <img
        className={styles.scopegradientorb1Icon}
        alt=""
        src="/scopegradientorb-1@2x.png"
        id="top"
      />
      {/* Countdown has begun -- !appsOpened && !appsFinished
          Best of luck. -- appsOpened && !appsFinished
          Applications have closed. -- appsOpened && appsFinished */}
      <div className={styles.applicationsForTheContainer}>
        {!appsOpened ?
        <p className={styles.applicationsForThe}>
          The countdown has begun.
          </p> : appsFinished ? 
          <p className={styles.applicationsForThe}>
          Applications for Spring 2025 have closed. Best of luck!
          </p> :
          <p className={styles.applicationsForThe}>
          Applications for the Spring 2025 Cohort are open.
          </p>}
        {/* <p className={styles.applicationsForThe}>
          Please check back next semester!
        </p> */}
        <p className={styles.applicationsForThe}>&nbsp;</p>
        {!appsFinished ? 
        <b className={styles.theCountdown}>
          {days} days&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{hours} hours&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{minutes} minutes&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{seconds} seconds
        </b> : <b className=""></b>} 
      </div>
      <div className={styles.mainlogo}>
        {/* <img className={styles.rectangleIcon} alt="" src="/rectangle@2x.png" />
        <img
          className={styles.rectangleIcon1}
          alt=""
          src="/rectangle1@2x.png"
        />
        <img
          className={styles.rectangleIcon2}
          alt=""
          src="/rectangle2@2x.png"
        /> */}
      </div>
      <div className={styles.learnBuildCode}>
              <TypedText/>
        </div>
      {/* <div className={styles.top} data-scroll-to="topText">
        @top
      </div> */}
      <img
        className={styles.hackathonPhotoIcon}
        alt=""
        src="/hackathon-photo@2x.png"
      />
      <a href="#about">
      <img className={styles.paulphotoIcon} alt="" src="/paulphoto@2x.png" /></a>
      <div className={styles.eachSemesterWe}>
        Each semester, we accept a cohort of approximately 30 students. Your
        cohort will become your family: you will work on projects, study, and
        have fun together. After your first semester in Scope, you are always
        encouraged to participate in meetings and events alongside the new cohorts.
      </div>
      <div className={styles.mottoFrame} id="about">
        <b className={styles.buildAndLearn}>
          Build and learn cool things with great people.
        </b>
        <div className={styles.thatsTheMottoContainer}>
          <p
            className={styles.mottoText}
          >{`That’s the motto that Scope was built on. `}</p>
          <p className={styles.mottoText}>&nbsp;</p>
          <p className={styles.mottoText}>
            As a Scoper, you will learn a new industry-standard framework
            every semester and flex your newfound skills by building a project with other members.
          </p>
          <p className={styles.mottoText}>&nbsp;</p>
          <p
            className={styles.mottoText}
          >{`Best of all, you will participate in community events alongside our tight-knit family of over 380 members and alumni. `}</p>
          <p className={styles.mottoText}>&nbsp;</p>
          <p className={styles.mottoText}>
            Scope hosts retreats, industry recruiting events, hackathons, and
            more, with the purpose of fostering an inclusive culture of creation at USC.
          </p>
        </div>
      </div>
      {/* <b className={styles.about} data-scroll-to="aboutText">
        @about
      </b> */}
      <b className={styles.ourCurriculum} id="about">Our Curriculum</b>
      {/* <b className={styles.learn} data-scroll-to="learnText">
        @learn
      </b> */}
      <b className={styles.theCohort}>The Cohort</b>
      {/* <b className={styles.cohort} data-scroll-to="cohortText">
        @cohort
      </b> */}
      <b className={styles.frequentlyAskedQuestions}>
        Frequently Asked Questions
      </b>
      <b className={styles.theBoard}>The Board</b>
      {/* <b className={styles.faq} data-scroll-to="faqText">
        @faq
      </b> */}
      <div className={styles.atScopeWe}>
        At Scope, we are always learning — so rather than focus on skills that
        are taught in USC’s core curriculum classes, you will learn a new
        technology or framework that is valuable in the tech industry right now.
        As a member, you will spend the first half of the semester learning about the
        curriculum topic through fun mini-projects, and the second half of the
        semester building something cool with your newfound skills.
      </div>
      <img
        className={styles.jakepointingIcon}
        alt=""
        src="/jakepointing@2x.png"
      />
      <div className={styles.linkStack}>
        <img
          className={styles.githubButtonIcon}
          alt=""
          src="/github-button@2x.png"
          onClick={onGithubButtonImageClick}
        />
        <img
          className={styles.facebookButtonIcon}
          alt=""
          src="/facebook-button@2x.png"
          onClick={onFacebookButtonImageClick}
        />
        <img
          className={styles.instagramButtonIcon}
          alt=""
          src="/instagram-button@2x.png"
          onClick={onInstagramButtonImageClick}
        />
        <div className={styles.about1} onClick={onABOUTTextClick}>
          ABOUT
        </div>
        {/* <div className={styles.scopeCup} onClick={onSCOPECUPTextClick}>
          CUP
        </div>
        <div className={styles.sponsor} onClick={onSPONSORTextClick}>
          SPONSOR
        </div> */}
        <div className={styles.faq1} onClick={onFAQTextClick}>
          FAQ
        </div>
        <div className={styles.cohort1} onClick={onCOHORTTextClick}>
          COHORT
        </div>
        <div className={styles.learn1} onClick={onLEARNTextClick}>
          LEARN
        </div>
      </div>
      <b className={styles.about2} onClick={onABOUTText1Click}>
        ABOUT
      </b>
      <b className={styles.faq2} onClick={onFAQText1Click}>
        FAQ
      </b>
      <b className={styles.cohort2} onClick={onCOHORTText1Click}>
        COHORT
      </b>
      <b className={styles.learn2} onClick={onLEARNText1Click}>
        LEARN
      </b>
      <div className={styles.scopeLogo} id="bigLogo">
        {/* <div className={styles.scopeOffWhite3d2}> */}
          {/* <img
            className={styles.rectangleIcon3}
            alt=""
            src="/rectangle3@2x.png"
          />
          <img
            className={styles.rectangleIcon1}
            alt=""
            src="/rectangle1@2x.png"
          />
          <img
            className={styles.rectangleIcon2}
            alt=""
            src="/rectangle4@2x.png"
          /> */}
        {/* </div> */}
      </div>
      <div className={styles.pillarsFrame}>
        <img
          className={styles.learnSymbolIcon}
          alt=""
          src="/learn-symbol@2x.png"
        />
        <img
          className={styles.bondSymbolIcon}
          alt=""
          src="/bond-symbol@2x.png"
        />
        <img
          className={styles.belongSymbolIcon}
          alt=""
          src="/belong-symbol@2x.png"
        />
        <div className={styles.learnACuttingEdge}>
          Learn a cutting-edge framework in the first half of the semester
          through stimulating weekly mini-projects.
        </div>
        <div className={styles.learn3}>Learn</div>
        <div className={styles.collaborate}>Collaborate</div>
        <div className={styles.belong}>Belong</div>
        <div className={styles.teamUpIdeate} id="learn">
          Team up, ideate, and develop with other members of your cohort in the
          second half of the semester to create innovative projects!
        </div>
        <div className={styles.joinATightKnit}>
          Join a tight-knit family of developers and make new friends through
          retreats, game nights, and other community building events.
        </div>
      </div>
      <img className={styles.arrow1Icon} alt="" src="/arrow-1@2x.png" />
      <b className={styles.theAlumni}>The Alumni</b>
      <div className={styles.curriculum}>
        <div className={styles.leftCurriculumColumn}>
          {/* <div className={styles.reactNativeSpring2022}>
            <div className={styles.reactNativeSpringContainer}>
              <p className={styles.mottoText}>Django</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2023</b>
              </p>
            </div>
            <img
              className={styles.reactlogo1Icon}
              alt=""
              src="/djangologo_720.png"
            />
          </div> */}
          <div className={styles.reactNativeSpring2022}>
            <div className={styles.reactNativeSpringContainer}>
              <p className={styles.mottoText}>Next JS</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2024</b>
                </p>
            </div>
            <img
              className={styles.nextlogoIcon}
              alt=""
              src="/next-js.svg"
            />
          </div>
          <div className={styles.reactNativeSpring2022}>
            <div className={styles.reactNativeSpringContainer}>
              <p className={styles.mottoText}>Django</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2023</b>
              </p>
            </div>
            <img
              className={styles.reactlogo1Icon}
              alt=""
              src="/djangologo_720.png"
            />
          </div>
          <div className={styles.nodeJsFall2022}>
            <img
              className={styles.nodejslogo1Icon}
              alt=""
              src="/nodejslogo-1@2x.png"
            />
            <div className={styles.nodeJsFallContainer}>
              <p className={styles.mottoText}>Node JS</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2022</b>
              </p>
            </div>
          </div>
          <div className={styles.reactJsAndTypescript}>
            <div className={styles.reactJsContainer}>
              <p
                className={styles.mottoText}
              >{`React JS & Typescript`}</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2021</b>
              </p>
            </div>
            <img
              className={styles.reactlogo1Icon1}
              alt=""
              src="/reactlogo-1@2x.png"
            />
          </div>
          <div className={styles.extensionsAndFirebase}>
            <div className={styles.extensionsFirebaseContainer}>
              <p
                className={styles.mottoText}
              >{`Extensions & Firebase`}</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2020 (VIRTUAL)</b>
              </p>
            </div>
            <img
              className={styles.chromelogo1Icon}
              alt=""
              src="/chromelogo-1@2x.png"
            />
          </div>
          <div className={styles.reactNativeSpring2022}>
            <div className={styles.reactJsFallContainer}>
              <p className={styles.mottoText}>React JS</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2019</b>
              </p>
            </div>
            <img
              className={styles.reactlogo1Icon2}
              alt=""
              src="/reactlogo-1@2x.png"
            />
          </div>
          <div className={styles.electron}>
            <div className={styles.electronFall2018Container}>
              <p className={styles.mottoText}>Electron</p>
              <p className={styles.fall2020Virtual}>
                <b>FALL 2018</b>
              </p>
            </div>
            <img
              className={styles.electronlogo1Icon}
              alt=""
              src="/electronlogo-1@2x.png"
            />
          </div>
          <div className={styles.reactNativeSpring2022}>
            <div className={styles.reactNativeFallContainer}>
              <p className={styles.mottoText}>React Native</p>
              <p className={styles.fall2020Virtual} id="cohort">
                <b>FALL 2017</b>
              </p>
            </div>
            <img
              className={styles.reactlogo1Icon}
              alt=""
              src="/reactlogo-1@2x.png"
            />
          </div>
        </div>
        <div className={styles.rightCurriculumColumn}>
          <div className={styles.reactJsAndFirebase}>
            <img
              className={styles.reactfalllogo1Icon}
              alt=""
              src="/reactlogo-1@2x.png"
            />
            <b className={styles.reactNativeSpring2022Container}>
              <p className={styles.mottoText}>React Native</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2025</b>
              </p>
            </b>
          </div>
          <div className={styles.tbdFall2023}>
            <div className={styles.tbdFall2023Container}>
              <p className={styles.mottoText}>Chrome Extensions</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2024</b>
              </p>
            </div>
            <div className={styles.questionmark}>
              {/* <img
                className={styles.shadowwhitequestionmark1Icon}
                alt=""
                src="/shadowwhitequestionmark-1@2x.png"
              /> */}
            <img
              className={styles.shadowwhitequestionmark1Icon}
              alt="chrome"
              src="/chromelogo-1@2x.png"
            />
            </div>
          </div>
          <div className={styles.reactJsAndFirebase}>
            <div className={styles.reactJsSpring2023Container}>
              <p
                className={styles.mottoText}
              >{`React JS & Firebase`}</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2023</b>
              </p>
            </div>
            <img
              className={styles.reactfalllogo1Icon}
              alt=""
              src="/reactlogo-1@2x.png"
            />
          </div>
          <div className={styles.reactJsAndFirebase}>
            <img
              className={styles.reactfalllogo1Icon}
              alt=""
              src="/reactlogo-1@2x.png"
            />
            <div className={styles.reactNativeSpring2022Container}>
              <p className={styles.mottoText}>React Native</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2022</b>
              </p>
            </div>
          </div>
          <div className={styles.swiftSpring2021}>
            <img
              className={styles.swiftlogo1Icon}
              alt=""
              src="/swiftlogo-1@2x.png"
            />
            <div className={styles.swiftSpring2021Container}>
              <p className={styles.mottoText}>Swift</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2021 (VIRTUAL)</b>
              </p>
            </div>
          </div>
          <div className={styles.electronSpring2020}>
            <img
              className={styles.electronlogo2Icon}
              alt=""
              src="/electronlogo-2@2x.png"
            />
            <div className={styles.electronSpring2020Container}>
              <p className={styles.mottoText}>Electron</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2020</b>
              </p>
            </div>
          </div>
          <div className={styles.flutterSpring2019}>
            <img
              className={styles.flutterlogo1Icon}
              alt=""
              src="/flutterlogo-1@2x.png"
            />
            <div className={styles.flutterSpring2019Container}>
              <p className={styles.mottoText}>Flutter</p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2019</b>
              </p>
            </div>
          </div>
          <div className={styles.nodejsBackendDevopsSpring}>
            <div className={styles.nodeJsBackendContainer}>
              <p className={styles.mottoText}>
                Node JS, Backend, DevOps
              </p>
              <p className={styles.fall2020Virtual}>
                <b>SPRING 2018</b>
              </p>
            </div>
            <img
              className={styles.nodejslogo2Icon}
              alt=""
              src="/nodejslogo-2@2x.png"
            />
          </div>
        </div>
      </div>
      <div className={styles.menuBarEnter} />
      <div className={styles.faq3}>
        <div className={styles.weAreAn}>
          We are an informal club that takes pride in building a community that is excited to ideate and
          create together. Scope is not an entrepreneurial club; it is a
          building org. This community is a trampoline that will launch
          meaningful ideas and relationships into the future.
        </div>
        <div className={styles.scopeLooksFor}>
          Scope looks for passionate people who love technology and building
          things. Since Scope consists of 2 pillars: learning and
          community, we look for applicants who value and will participate in
          both of these aspects.
        </div>
        <div className={styles.scopeIsTo}>
          Scope is open to undergraduate USC students from all majors. While many of
          our members are computer science students or people interested in the
          technology field, we encourage and welcome members from other sectors,
          and love what their unique experiences and insights bring to the club.
        </div>
        <div className={styles.sinceOurCurriculum}>
          Since our curriculum does require a basic foundation of coding, we
          require this of our applicants as well. This means you have seen and
          touched code before whether that be through a class, personal project,
          internship, etc. Please elaborate on your application.
        </div>
        <div className={styles.applicationAtContainer}>
          <body className={styles.mottoText}>
            Application - At the beginning of each semester we release an
            application to hear a little bit about your experience and why you
            want to join Scope.
          </body>
          <body className={styles.mottoText}>&nbsp;</body>
          <body className={styles.mottoText}>
            Interview - Get to know Scope e-board while we get to know you! The goal of this stage
            is to assess how you would fit in with our Scope family.
          </body>
          <body className={styles.mottoText}>&nbsp;</body>
          <body className={styles.mottoText}>
            Decision - If you are admitted, yay! If not, that doesn’t mean you
            weren’t right for the club, it’s that we didn’t have the space for
            you yet. But please, please reapply in future semesters!
          </body>
        </div>
        <div className={styles.around30}>Around 30.</div>
        <div className={styles.weHaveTwo}>
          We have two mandatory weekly meetings (typically Tuesdays and Thursdays).
          In addition, Scope members typically spend 2-4 hours per week
          participating in club-related activities like social events and
          projects.
        </div>
        <b className={styles.mission}>Mission</b>
        <b className={styles.qWhatMakes}>
          Q: What makes Scope different from other organizations?
        </b>
        <b className={styles.qWhatDoes}>
          Q: What does Scope look for in applicants?
        </b>
        <b className={styles.qWhoIs}>Q: Who is eligible to apply?</b>
        <b className={styles.qDoI}>Q: Do I need coding experience to join?</b>
        <b className={styles.qWhatDoes1}>
          Q: What does the application process look like?
        </b>
        <b className={styles.qHowMany}>
          Q: How many members does Scope take each semester?
        </b>
        <b className={styles.qWhatIs}>Q: What is the time commitment?</b>
        <b className={styles.applicationProcess}>Application Process</b>
        <b className={styles.involvement}>{`Involvement `}</b>
      </div>
      <div className={styles.ourNetworkOf}>
        Our network of 380+ alumni have gone on to be Software Engineers,
        Product Managers, and more at:
      </div>
      {/* <div className={styles.ourCopyright}>
      © scope 2023
      </div> */}
      {/* <img className={styles.ourCopyright} src="/silverInfinity.png"/> */}
      {/* <div className={styles.ourCopyright2}>
      designed by Stephanie Yu
      </div> */}
      <div className={styles.bottomDiv}>
  {/*   <img
        className={styles.bottomGraphicIcon}
        alt=""
        src="/bottom-graphic@2x.png"
    /> */}
      </div>
      <div className={styles.ourExecutiveBoard}>
        Our executive board is responsible for the management of Scope. They
        handle recruiting new members, organizing and teaching curriculum, and
        putting on fun social and professional events.
      </div>
      <div className={styles.alumniCompanies}>
        <img className={styles.googleIcon} alt="" src="/google@2x.png" />
        <img className={styles.amazonIcon} alt="" src="/amazon@2x.png" />
        <img className={styles.microsoftIcon} alt="" src="/microsoft@2x.png" />
        <img className={styles.coinbaseIcon} alt="" src="/coinbase@2x.png" />
        <img className={styles.snapchatIcon} alt="" src="/snapchat@2x.png" />
        <img className={styles.bloombergIcon} alt="" src="/bloomberg@2x.png" />
        <img className={styles.metaLogoIcon} alt="" src="/meta-logo@2x.png" />
        <img
          className={styles.linkedinLogoIcon}
          alt=""
          src="/linkedin-logo@2x.png"
        />
        <img
          className={styles.stripeLogoIcon}
          alt=""
          src="/stripe-logo@2x.png"
        />
      </div>
      <img
        className={styles.rollercoasterphoto1Icon}
        alt=""
        src="/rollercoasterphoto-1@2x.png"
      />
      <div className={styles.eboardPhotos}>
        {/* <img
          className={styles.paulScreenshot1Icon}
          alt=""
          src="/Paul_Photo.png"
        /> */}
          <div className = {styles.hover} onClick={() => goToLinkedin('halliefaust')}>
            <div className={styles.overlay1}>
            <div className= {styles.name}>{"Hallie"}
              <br></br>
              <span className={styles.role}>{"President"}</span>
            </div>
            </div>
            <div className={styles.eboardImg1}>
              <img src={"Hallie_Photo.png"} alt={"Hallie"} width={200} height={200} />
            </div>
          </div>
          <div className = {styles.hover} onClick={() => goToLinkedin('gabrielagutzmer')}>
            <div className={styles.overlay2}>
            <div className= {styles.name}>{"Gabby"}
              <br></br>
              <span className={styles.role}>{"President"}</span>
            </div>
            </div>
            <div className={styles.eboardImg2}>
              <img src={"/Gabby_Photo.png"} alt={"Gabby"} width={200} height={200} />
            </div>
          </div>
        <div className = {styles.hover} onClick={() => goToLinkedin('deborahmok707')}>
            <div className={styles.overlay3}>
            <div className= {styles.name}>{"Deborah"}
              <br></br>
              <span className={styles.role}>{"Brand"}</span>
            </div>
            </div>
            <div className={styles.eboardImg3}>
              <img src={"/Deborah_Photo.png"} alt={"Deborah"} width={200} height={200} />
            </div>
          </div>
        <div className = {styles.hover} onClick={() => goToLinkedin('joyce-ng-2a89b828b')}>
            <div className={styles.overlay4}>
            <div className= {styles.name}>{"Joyce"}
              <br></br>
              <span className={styles.role}>{"Finances"}</span>
            </div>
            </div>
            <div className={styles.eboardImg4}>
              <img src={"/Joyce_Photo.png"} alt={"Joyce"} width={200} height={200} />
            </div>
          </div>
        <div className = {styles.hover} onClick={() => goToLinkedin('jkchisolm')}>
            <div className={styles.overlay5}>
            <div className= {styles.name}>{"Josh"}
              <br></br>
              <span className={styles.role}>{"Curriculum"}</span>
            </div>
            </div>
            <div className={styles.eboardImg5}>
              <img src={"/Josh_Photo.png"} alt={"Josh"} width={200} height={200} />
            </div>
          </div>
        <div className = {styles.hover} onClick={() => goToLinkedin('valeria-g-166000228')}>
            <div className={styles.overlay6}>
            <div className= {styles.name}>{"Valeria"}
              <br></br>
              <span className={styles.role}>{"Curriculum"}</span>
            </div>
            </div>
            <div className={styles.eboardImg6}>
              <img src={"/Valeria_Photo.png"} alt={"Valeria"} width={200} height={200} />
            </div>
          </div>
        <div className = {styles.hover} onClick={() => goToLinkedin('adit-hattikudur')}>
            <div className={styles.overlay8}>
            <div className= {styles.name}>{"Adit"}
              <br></br>
              <span className={styles.role}>{"Events"}</span>
            </div>
            </div>
            <div className={styles.eboardImg8}>
              <img src={"/Adit_Photo.png"} alt={"Adit"} width={200} height={200} />
            </div>
          </div>
          <div className = {styles.hover} onClick={() => goToLinkedin('anaymody')}>
            <div className={styles.overlay7}>
            <div className= {styles.name}>{"Adit"}
              <br></br>
              <span className={styles.role}>{"Outreach"}</span>
            </div>
            </div>
            <div className={styles.eboardImg7}>
              <img src={"/Anay_Photo.png"} alt={"Anay"} width={200} height={200} />
            </div>
          </div>

      </div>
      <div className={styles.cohort3}>
        <div className={styles.cohort11}>
          <img
            className={styles.froodphoto1Icon}
            alt=""
            src="/groupphoto2-2-1@2x.png"
          />
        </div>
        <div className={styles.cohort21}>
          <img
            className={styles.froodphoto1Icon}
            alt=""
            src="/froodphoto-1@2x.png"
          />
        </div>
        <div className={styles.cohort31}>
          <img
            className={styles.froodphoto1Icon}
            alt=""
            src="/paintphoto-1@2x.png"
          />
        </div>
      </div>
      <nav className={styles.menuBar} id="menuBar">
        <div className={styles.logo}>
          {/* <img
            className={styles.purpleinf1Icon}
            alt=""
            src="/purpleinf-1@2x.png"
            onClick={onPurpleinf1ImageClick}
          /> */}
          <div className={styles.menuLogo} onClick={onLogoClick}></div>
        </div>
        <div className={styles.about3} onClick={onABOUTText2Click}>
          ABOUT
        </div>
        <div className={styles.learn4} onClick={onLEARNText2Click}>
          LEARN
        </div>
        <div className={styles.cohort4} onClick={onCOHORTText2Click}>
          COHORT
        </div>
        <div className={styles.faq4} onClick={onFAQText2Click}>
          FAQ
        </div>
        {/* <div className={styles.sponsor2} onClick={onSPONSORText2Click}>
          SPONSOR
        </div> */}
        {/* <div className={styles.scopeCup2} onClick={onSCOPECUPText2Click}>
          CUP
        </div> */}
      </nav>
    </div>}
     </div>
  );
};

export default MainPage;
